/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react';
import { Link } from 'gatsby';

import 'typeface-pacifico';
import styles from './header.module.styl';

const Header = () => (
  <header className={`row ${styles.container}`} data-aos="fade-down">
    <div className={`two columns ${styles.logoColumn}`} title="Go Home">
      <Link to="/" className={styles.logo} />
    </div>
    <h2 className={styles.title}>Singles Dating Events. Match in Action</h2>
  </header>
);

export default Header;
