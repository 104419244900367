import queryString from 'query-string';

export function parseRef(location) {
  if (location && location.search) {
    const parsed = queryString.parse(location.search);

    if (parsed.ref) {
      localStorage.setItem('param:ref', parsed.ref);
    } else if (Object.keys(parsed).length === 1) {
      localStorage.setItem('param:ref', Object.keys(parsed)[0]);
    }
  }
}

export function getRef() {
  return localStorage.getItem('param:ref');
}
