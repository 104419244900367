import React from 'react';

import 'typeface-pacifico';
import 'typeface-mali';
import styles from './footer.module.css';

const Footer = () => (
  <footer className="row">
    <div className={`twelve columns ${styles.container}`}>
      <p className={styles.slogan}>
        Dating should be fun. Your dream date might be just an event away!
      </p>
      <p className={styles.rights}>© Singles Dating Events</p>
    </div>
  </footer>
);

export default Footer;
